import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './YearSelect.css';

const YearSelect = (params) => {
    const { year: yearParam } = useParams();
    let lang = params.lang;
    const [year, setYear] = useState(yearParam || 2025);
    const navigate = useNavigate();

    useEffect(() => {
        if (yearParam) {
            setYear(yearParam);
        }
    }, [yearParam]);

    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        setYear(selectedYear);
        navigate(`/${lang ? lang + '/' : ''}${selectedYear}`);
    };

    return (
        <div className='year-select'>
            <label htmlFor="year">{lang === 'en' ? 'Tax year:' : 'Skatteår:'} </label>
            <select id="year" value={year} onChange={handleYearChange}>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
            </select>
        </div>
    );
};

export default YearSelect;