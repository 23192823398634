import React from 'react';
import './App.css';
import Calculator from './components/Calculator';
import LanguageSelect from './components/LanguageSelect';
import YearSelect from './components/YearSelect';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="container">
          <Routes>
            <Route path="/en/:year" element={<>
              <div className="navBar">
                <YearSelect lang="en" />
                <LanguageSelect />
              </div>
              <Calculator language="en" />
            </>} />
            <Route path="/en" element={<>
              <div className="navBar">
                <YearSelect lang="en" />
                <LanguageSelect />
              </div>
              <Calculator language="en" />
            </>} />
            <Route path="/da/:year" element={<>
              <div className="navBar">
                <YearSelect lang="da" />
                <LanguageSelect />
              </div>
              <Calculator language="da" />
            </>} />
            <Route path="/da" element={<>
              <div className="navBar">
                <YearSelect lang="da" />
                <LanguageSelect />
              </div>
              <Calculator language="da" />
            </>} />
            <Route path="/" element={<>
              <div className="navBar">
                <YearSelect lang="da" />
                <LanguageSelect />
              </div>
              <Calculator language="da" />
            </>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;