import React from "react";
import i18next from "i18next";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import './LanguageSelector.css';

const LanguageSelector = () => {
  const { year } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (event) => {
    const selectedLang = event.target.name;
    i18next.changeLanguage(selectedLang);
    navigate(`/${selectedLang}${year ? '/' + year : ''}`);
  };

  const isDanskActive = () => {
    return location.pathname === '/' || location.pathname.startsWith('/da');
  };

  return (
    <div className="language-selector">
      <NavLink
        onClick={changeLanguage}
        name="da"
        to="/"
        className={isDanskActive() ? 'active' : ''}
      >
        Dansk
      </NavLink>|
      <NavLink
        onClick={changeLanguage}
        name="en"
        to="/en"
      >
        English
      </NavLink>
    </div>
  );
};

export default LanguageSelector;